@font-face {
  font-family: 'Noto Color Emoji';
  src: url('./fonts/NotoColorEmoji-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.animate-extend {
  animation-name: extend;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes extend {
  100% {
    width: 100%;
  }
}

.animate-fade-in {
  animation: fadeIn 2s linear 1;
  animation-fill-mode: forwards;
}

.animate-fade-in-fast {
  animation: fadeIn 0.5s linear 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade-out {
  animation: fadeOut 2s linear 1;
  animation-fill-mode: forwards;
}

.animate-fade-out-fast {
  animation: fadeOut 0.5s linear 1;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.square {
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
}
